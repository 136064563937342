var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        "mini-variant": "",
        app: "",
        clipped: "",
        "mobile-breakpoint": "600",
        "mini-variant-width": "65",
        color: "sidebarLight",
        "data-intro":
          "You can still access all courses, research spaces and datasets through the sidebar. ",
        "data-step": "3",
        permanent: ""
      }
    },
    [
      _c(
        "v-tooltip",
        {
          attrs: { right: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-list-item",
                    _vm._g(
                      {
                        attrs: {
                          to: {
                            name: "home-dashboard",
                            params: { oid: _vm.$route.params.oid }
                          }
                        }
                      },
                      on
                    ),
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("bar_chart")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Dashboard")])
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v("Main dashboard")])]
      ),
      _c("v-divider"),
      _c(
        "v-tooltip",
        {
          attrs: { right: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-list-item",
                    _vm._g(
                      {
                        attrs: {
                          to: {
                            name: "home-education-spaces",
                            params: { oid: _vm.$route.params.oid }
                          }
                        }
                      },
                      on
                    ),
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("school")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v(" Courses ")])
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v("Courses")])]
      ),
      _c(
        "v-tooltip",
        {
          attrs: { right: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-list-item",
                    _vm._g(
                      {
                        attrs: {
                          to: {
                            name: "home-research-spaces",
                            params: { oid: _vm.$route.params.oid }
                          }
                        }
                      },
                      on
                    ),
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-beaker")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v(" Research Projects ")])
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v("Research Projects")])]
      ),
      _c(
        "v-tooltip",
        {
          attrs: { right: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-list-item",
                    _vm._g(
                      {
                        attrs: {
                          to: {
                            name: "home-dataset-spaces",
                            params: { oid: _vm.$route.params.oid }
                          }
                        }
                      },
                      on
                    ),
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-database")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v(" Datasets ")])
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v("Datasets")])]
      ),
      _c("v-divider"),
      _vm.isOrgManager === true || _vm.isOrgFaculty === true
        ? _c(
            "v-menu",
            {
              attrs: {
                "close-on-content-click": true,
                "offset-x": "",
                "max-width": "340",
                "open-on-hover": "",
                "close-delay": _vm.closingDelay
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g({}, on),
                          [
                            _c(
                              "v-list-item-icon",
                              [_c("v-icon", [_vm._v("settings")])],
                              1
                            ),
                            _c("v-list-item-title", [_vm._v("settings")])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3237631703
              ),
              model: {
                value: _vm.manageMenu,
                callback: function($$v) {
                  _vm.manageMenu = $$v
                },
                expression: "manageMenu"
              }
            },
            [_c("OrgSettingsMenu")],
            1
          )
        : _vm._e(),
      _vm.isOrgManager === true
        ? _c("div", [_c("ReportingDashboard")], 1)
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { attrs: { nav: "", dense: "" } },
    [
      _c(
        "v-list-item",
        [
          _c("v-list-item-content", [
            _c(
              "div",
              {
                staticClass: "d-flex flex-column",
                staticStyle: { width: "100%" }
              },
              [
                _c("span", { staticClass: "caption text-uppercase" }, [
                  _vm._v("manage organization")
                ]),
                _c("v-divider", {
                  staticClass: "mt-1",
                  attrs: { color: "grey" }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.isOrgManager === true
        ? _c(
            "v-list-item",
            { attrs: { to: { name: "home-org-users" } } },
            [
              _c("v-list-item-title", [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-1", attrs: { small: "" } },
                      [_vm._v("mdi-account-group-outline")]
                    ),
                    _vm._v("Organization users")
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-list-item",
        {
          attrs: {
            to: {
              name: "home-org-tokens"
            }
          }
        },
        [
          _c("v-list-item-title", [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-1",
                    attrs: { color: "primary", small: "" }
                  },
                  [_vm._v("mdi-key")]
                ),
                _vm._v("Organization tokens")
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
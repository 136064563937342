<template>
    <v-list nav dense>
        <v-list-item>
            <v-list-item-content>
                <div class="d-flex flex-column" style="width:100%">
                    <span class="caption text-uppercase">manage organization</span>
                    <v-divider class="mt-1" color="grey"></v-divider>
                </div>
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="isOrgManager === true" :to="{ name: 'home-org-users' }">
            <v-list-item-title>
                <div class="d-flex align-center"><v-icon small class="mr-1">mdi-account-group-outline</v-icon>Organization users</div></v-list-item-title
            >
        </v-list-item>
        <v-list-item
            :to="{
                name: 'home-org-tokens'
            }"
        >
            <v-list-item-title
                ><div class="d-flex align-center"><v-icon color="primary" class="mr-1" small>mdi-key</v-icon>Organization tokens</div></v-list-item-title
            >
        </v-list-item>
    </v-list>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'OrgSettingsMenu',
    computed: {
        ...mapGetters('orgStore', ['isOrgManager'])
    }
}
</script>

<template>
    <v-navigation-drawer
        mini-variant
        app
        clipped
        mobile-breakpoint="600"
        mini-variant-width="65"
        color="sidebarLight"
        data-intro="You can still access all courses, research spaces and datasets through the sidebar. "
        data-step="3"
        permanent
    >
        <v-tooltip right>
            <template v-slot:activator="{ on }">
                <v-list-item v-on="on" :to="{ name: 'home-dashboard', params: { oid: $route.params.oid } }">
                    <v-list-item-icon>
                        <v-icon>bar_chart</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Dashboard</v-list-item-title>
                </v-list-item>
            </template>
            <span>Main dashboard</span>
        </v-tooltip>
        <v-divider />
        <v-tooltip right>
            <template v-slot:activator="{ on }">
                <v-list-item v-on="on" :to="{ name: 'home-education-spaces', params: { oid: $route.params.oid } }">
                    <v-list-item-icon>
                        <v-icon>school</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        Courses
                    </v-list-item-title>
                </v-list-item>
            </template>
            <span>Courses</span>
        </v-tooltip>
        <v-tooltip right>
            <template v-slot:activator="{ on }">
                <v-list-item v-on="on" :to="{ name: 'home-research-spaces', params: { oid: $route.params.oid } }">
                    <v-list-item-icon>
                        <v-icon>mdi-beaker</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        Research Projects
                    </v-list-item-title>
                </v-list-item>
            </template>
            <span>Research Projects</span>
        </v-tooltip>
        <v-tooltip right>
            <template v-slot:activator="{ on }">
                <v-list-item v-on="on" :to="{ name: 'home-dataset-spaces', params: { oid: $route.params.oid } }">
                    <v-list-item-icon>
                        <v-icon>mdi-database</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        Datasets
                    </v-list-item-title>
                </v-list-item>
            </template>
            <span>Datasets</span>
        </v-tooltip>
        <v-divider />
        <v-menu
            v-if="isOrgManager === true || isOrgFaculty === true"
            :close-on-content-click="true"
            offset-x
            v-model="manageMenu"
            max-width="340"
            open-on-hover
            :close-delay="closingDelay"
        >
            <template v-slot:activator="{ on }">
                <v-list-item v-on="on">
                    <v-list-item-icon>
                        <v-icon>settings</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>settings</v-list-item-title>
                </v-list-item>
            </template>
            <OrgSettingsMenu />
        </v-menu>
        <div v-if="isOrgManager === true">
            <ReportingDashboard />
        </div>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import OrgSettingsMenu from './TheHomeOrgSettingsMenu'
import ReportingDashboard from '@/components/ReportingDashboardDialog'
export default {
    components: { OrgSettingsMenu, ReportingDashboard },
    data() {
        return {
            manageMenu: false,
            closingDelay: 100
        }
    },
    computed: {
        ...mapGetters('orgStore', ['isOrgManager', 'isOrgFaculty'])
    }
}
</script>

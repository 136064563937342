import { render, staticRenderFns } from "./TheHomeSideBar.vue?vue&type=template&id=5ed0d556&"
import script from "./TheHomeSideBar.vue?vue&type=script&lang=js&"
export * from "./TheHomeSideBar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VDivider,VIcon,VListItem,VListItemIcon,VListItemTitle,VMenu,VNavigationDrawer,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5ed0d556')) {
      api.createRecord('5ed0d556', component.options)
    } else {
      api.reload('5ed0d556', component.options)
    }
    module.hot.accept("./TheHomeSideBar.vue?vue&type=template&id=5ed0d556&", function () {
      api.rerender('5ed0d556', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/home/components/TheHomeSideBar.vue"
export default component.exports